import IndexContainer from 'containers/IndexContainer';
import { fetchIndexData } from 'data/indexData';
import { pathnames } from 'i18n/pathnames';
import { GetStaticPropsContext, Page } from 'next';

export const getStaticProps = async (context: GetStaticPropsContext) => fetchIndexData(context, pathnames.INDEX);

const Index: Page = () => <IndexContainer />;

export default Index;

Index.getLayout = IndexContainer.getLayout;
